<template>
  <div v-if="counters.length" ref="counterRef" class="counters" :class="{ '--light': light }">
    <div v-for="(counter, i) in counters" :key="counter.title" class="counter" data-aos="fade-up">
      <img
        v-if="counter.icon && counter.icon.mediaItemUrl"
        v-lazy-load :data-src="counter.icon.mediaItemUrl"
        class="counter-icon"
        role="presentation"
      />
      <p class="value">
        <span
          :data-target="counter.value"
          :data-tick="options[i].tick"
          :data-speed="options[i].speed"
          :data-delay="options[i].delay"
          class="counter-data"
          >0</span
        >
        <span
          v-if="counter.subvalue"
          class="subvalue"
          :class="{ '--large': counter.largeSubvalue }"
          >{{ counter.subvalue }}</span
        >
      </p>
      <p class="label">{{ counter.title }}</p>
    </div>
  </div>
</template>

<script>
import { isElementInViewport } from '@/utils/is-element-in-viewport'

const DEFAULT_TARGET = 1000
const DEFAULT_DELAY = 0
const DEFAULT_TICK = 10
const DEFAULT_SPEED = 10

export default {
  name: 'AppCounters',
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    counters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      counterRef: null,
      DEFAULT_TARGET,
      DEFAULT_DELAY,
      DEFAULT_TICK,
      DEFAULT_SPEED,
      options: [
        {
          tick: 4,
          delay: 0,
          // to nie speed tylko timeout ale brakuje mi słowa xd
          speed: 32,
        },
        {
          tick: 148,
          delay: 400,
          speed: 1,
        },
        {
          tick: 1,
          delay: 200,
          speed: 100,
        },
      ],
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.$nextTick(() => {
      this.counterRef = this.$refs?.counterRef

      if (this.counterRef && isElementInViewport(this.counterRef)) {
        this.handleCounters()
        return
      }

      if (this.counterRef) window.addEventListener('scroll', this.handleScroll)
    })
  },
  methods: {
    handleCounters() {
      window.removeEventListener('scroll', this.handleScroll)
      const counters = this.$refs.counterRef.querySelectorAll('.counter-data')

      counters.forEach((counter) => {
        const delay = parseInt(counter.dataset.delay) || DEFAULT_DELAY
        setTimeout(() => {
          const updateCount = () => {
            const target = parseInt(counter.dataset.target) || DEFAULT_TARGET
            const count = parseInt(counter.innerText)
            const tick = parseInt(counter.dataset.tick) || DEFAULT_TICK
            const speed = parseInt(counter.dataset.speed) || DEFAULT_SPEED

            if (count < target) {
              window.requestAnimationFrame(() => {
                counter.innerText = count + tick
              })
              setTimeout(updateCount, speed)
            } else {
              window.requestAnimationFrame(() => {
                counter.innerText = parseFloat(target).toLocaleString('en').replace(/,/g, ' ')
              })
            }
          }

          updateCount()
        }, delay)
      })
    },
    handleScroll() {
      if (isElementInViewport(this.counterRef)) {
        this.handleCounters()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/AppCounters';
</style>
